import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

import { LineChart, BarChart, PieChart, ScatterChart } from '@mui/x-charts';
import Table from 'react-bootstrap/Table';

import React, { useEffect, useContext, useState, useRef, createRef } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import AuthContext from '../../helpers/AuthContext';

import ErrorBoundary from '../../helpers/ErrorBoundary';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ChevronDoubleLeft, ChevronDoubleRight, PlusSquare, Send, Trash, Trash2 } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ConversationEdit from "./edit_conversation";
import ConfirmMessage from "./confirm";
import Spinner from 'react-bootstrap/Spinner';

import { Repeat } from 'react-bootstrap-icons';

import api from '../../api';

function Assistant() {
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [conversations, setConversations] = useState([]);
    const [activeConversation, setActiveConversation] = useState("");
    const [history, setHistory] = useState({});
    const [summary, setSummary] = useState("");
    const [currentMessage, setCurrentMessage] = useState("");
    const [isAILoading, setIsAILoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isConvOpen, setIsConvOpen] = useState(true);

    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const { t } = useTranslation();

    let stream = null;

    let convId = useRef();
    convId.current = activeConversation;

    let historyRef = useRef();
    historyRef.current = history;

    const chatHistoryWindow = useRef(null);

    function sendUserMessage(msg) {
        const payload = {
            "message": msg,
            "conversation_id": activeConversation,
        };
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/chatstream";
        return api.post(INFO_ENDPOINT, payload, {
            withCredentials: true,
        });
    }

    function loadAssistantConversations() {
        setIsLoading(true);
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversations";
        return api.get(INFO_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error) {
                setConversations(res.data.conversations);
                if (activeConversation == "") {
                    let sConvId = localStorage.getItem("conversation_id");
                    if (sConvId != "") {
                        for (let conv of res.data.conversations){
                            if (conv._id == sConvId) {
                                loadHistory(sConvId);
                                getSummary(sConvId);
                                break;
                            }
                        }
                    }
                }
            }
            setIsLoading(false);
        });
    }

    function loadHistory(conversation_id) {
        setHistory({});
        setIsAILoading(false);
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/history";
        return api.get(INFO_ENDPOINT, {
            params: { conversation_id: conversation_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error && res.data.history) {
                try{
                    let len = res.data.history?.messages?.length;
                    if (len > 0) {
                        res.data.history.messages[len-1].what.message = JSON.parse(res.data.history.messages[len-1].what.message);
                    }
                } catch(err) {}
                setHistory(Object.assign({}, res.data.history));
                setActiveConversation(conversation_id);
                localStorage.setItem("conversation_id", conversation_id);
                // subscribeToSocket();
                setTimeout(() => {
                    // let messages = document.getElementsByClassName("message");
                    // if (messages.length > 0) {
                    //     let message = messages[messages.length - 1];
                    //     // message.scrollIntoView({ behavior: "instant" })
                        
                    // }
                    chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "instant" });
                }, 100);
                return res.data.history;
            }
            return [];
        });
    }

    function newConversation() {
        setActiveConversation("");
        setShowEdit(true);
    }

    function handleDelete(id) {
        setToDelete(id);
        setShowDelete(true);
    }

    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversation";
        api.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadAssistantConversations();
            setActiveConversation("");
            setIsAILoading(false);
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function handleLoading() {
        setIsAILoading(true);
        let loaders = document.getElementsByClassName("ai-loading");
        if (loaders.length > 0) {
            let loader = loaders[0];
            loader.scrollIntoView();
        }
    }

    function handleReLoading() {
        loadHistory(convId.current);
    }

    function checkForAnswerFromAI() {
        setTimeout(() => {
            let msgs = historyRef.current.messages;
            if (isAILoading && msgs.length > 0) {
                let lastMsg = msgs[msgs.length - 1];
                if (lastMsg.who === "assistant") {
                    setIsAILoading(false);
                } else {
                    loadHistory(convId.current).then((newHistory) => {
                        let l = newHistory.messages.length;
                        if (l > 0) {
                            let lastMsg = newHistory.messages[l - 1];
                            if (lastMsg.who === "assistant") {
                                setIsAILoading(false);
                            } else {
                                checkForAnswerFromAI();
                            }
                        }
                    });
                }
            }
        }, 1000);
    }

    const handleMessage = (event) => {
        if (event.data.startsWith("{")) {
            try {
                let message = "";
                try{
                    if (event.data.includes("\n")) {
                        event.data = event.data.replace(/\n/g, "<br>");
                    }
                    if (event.data.includes("\"\"\"")) {
                        event.data = event.data.replace(/\"\"\"/g, "\"\\\"\"");
                    }
                    message = JSON.parse(event.data);
                    if (!historyRef.current.messages || convId.current != message.data.conversation_id) {
                        return;
                    }
                } catch(err){
                    // message = {"type":"chat", "data": {"chunk": ""}};
                    return;
                }
                let l = historyRef.current.messages.length;
                switch (message.type) {
                    case "chat":
                        if (l > 0) {
                            let lastMsg = historyRef.current.messages[l - 1];
                            if (lastMsg.who == "user") {
                                let mDate = new Date(new Date().setSeconds(new Date().getSeconds() + 5))
                                mDate.setSeconds(mDate.getSeconds() + 1);
                                var newMessage = {
                                    who: "assistant",
                                    when: moment.utc(mDate).format("YYYY-MM-DD HH:mm:ss"),
                                    what: {
                                        message: { "response": message.data.chunk },
                                    }
                                };
                                let l_history = historyRef.current.messages ? [...historyRef.current.messages, newMessage] : [newMessage];
                                historyRef.current.messages = l_history;
                                setIsAILoading((prev) => {
                                    return false;
                                })

                                // lastMsg.what.message.response += message.data.chunk;

                                setHistory((prev) => Object.assign({}, historyRef.current));
                                setTimeout(() => {
                                    chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "smooth" });    
                                }, 10)
                            } else {
                                lastMsg.what.message.response += message.data.chunk;

                                setHistory((prev) => {
                                    return Object.assign({}, historyRef.current);
                                });
                                setTimeout(() => {
                                    chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "smooth" });    
                                }, 10)
                            }
                        }
                    break;
                    case "visualization":
                        if (l > 0) {
                            let lastMsg = historyRef.current.messages[l - 1];
                            if (lastMsg.who == "assistant") {
                                lastMsg.what.message.visualization = message.data.visualization;
                                setHistory((prev) => {
                                    return Object.assign({}, historyRef.current);
                                });
                                setTimeout(() => {
                                    chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "smooth" });    
                                }, 100)
                            }
                        }
                    break;
                }
            } catch(err){
                console.log(err);
            }
        } else if (event.data === "summary") {
            getSummary(convId.current);
        } else {
            console.log(event.data);
            // loadHistory(convId.current).then((newHistory) => {
            //     let l = newHistory.messages.length;
            //     if (l > 0) {
            //         let lastMsg = newHistory.messages[l - 1];
            //         if (lastMsg.who === "assistant") setIsAILoading(false);
            //     }
            // });
            // if (isAILoading) {
            //     checkForAnswerFromAI();
            // }
        }
    }

    function onSocketConnect() {
    }

    function onSocketDisonnect() {
    }

    function onSocketError(e) {
        console.error("Error: " + e);
    }
    
    function subscribeToSocket(uid) {
        if (stream !== null) {
            stream.removeEventListener("reload", handleReLoading);
            stream.removeEventListener("loading", handleLoading);
            stream.removeEventListener("message", handleMessage);

            stream.removeEventListener("open", onSocketConnect);
            stream.removeEventListener("error", onSocketError);
            stream.removeEventListener("close", onSocketDisonnect);

            stream.close();
            stream = null;
        }

        let roomID = userId;
        if (!userId) {
            roomID = uid;
        }

        const SOCKET_ENDPOINT = process.env.REACT_APP_API_URL.replace("http", "ws") + "stream/" + roomID;

        stream = new WebSocket(SOCKET_ENDPOINT);
        stream.addEventListener("reload", handleReLoading);
        stream.addEventListener("loading", handleLoading);
        stream.addEventListener("message", handleMessage);

        stream.addEventListener("open", onSocketConnect);
        stream.addEventListener("error", onSocketError);
        stream.addEventListener("close", onSocketDisonnect);

        window.addEventListener("unload", function () {
            if (stream.readyState === WebSocket.OPEN) stream.close();
        });
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                subscribeToSocket(res);
                loadAssistantConversations();
            }
        });
        return () => {
            if (stream !== null && stream.readyState === WebSocket.OPEN) {
                stream.close();
                stream = null;
            }
        };
    }, [])

    const sendMessage = () => {
        if (currentMessage.length === 0) return;
        var newMessage = {
            who: "user",
            when: moment.utc(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            what: {
                message: currentMessage,
            }
        };
        let l_history = history.messages ? [...history.messages, newMessage] : [newMessage];
        history.messages = l_history;
        setHistory(Object.assign({}, history));
        setTimeout(() => {
            chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "smooth" });    
        }, 10)
        sendUserMessage(currentMessage).then(() => {
            setCurrentMessage("");
            setIsAILoading(true);
            setTimeout(() => {
                chatHistoryWindow.current.scrollTo({top:chatHistoryWindow.current.scrollHeight, behavior: "smooth" });    
            }, 10)
        })
    }
    
    function CTree(data, level = 0) {
        if (typeof data === 'object' && data !== null) {
            if (Array.isArray(data)) {
                // If data is an array, map through each element and call Tree
                // return `<ul>
                //         ${
                //             data.map((item, index) => `
                //                 <li>
                //                     ${CTree(item, level+1)}
                //                 </li>
                //             `).join('')
                //         }
                //     </ul>
                // `;
                return `
                    ${
                        data.map((item, index) => `
                                ${CTree(item, level+1)}
                        `).join('')
                    }
                `;
            } else {
                // If data is an object, render each key-value pair
                return `<ul>
                        ${
                            Object.entries(data).map(([key, value]) => `
                                <li>
                                    <strong>${key}:</strong> ${CTree(value, level+1)}
                                </li>
                            `).join('')
                        }
                    </ul>
                `;
            }
        }else{
            return `<span>${String(data)}</span>`;
        }
    };

    function reloadSummary(){
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/summary/reload";
        return api.get(INFO_ENDPOINT, {
            params: { conversation_id: convId.current },
            withCredentials: true,
        });
    }

    function getSummary(conversation_id){
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/summary";
        return api.get(INFO_ENDPOINT, {
            params: { conversation_id: conversation_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error && res.data.summary) {
                setSummary(res.data.summary);
            }
        });
    }

    const myErrorHandler = (error, componentStack) => {
        console.error(error);
    };

    return (
        <Container className="main-container main-container-assistant">
            <Navbar></Navbar>
            <h3>{t("AI Assistant")}</h3>
            <Container className="p-0 p-md-4 container-fluid mb-5 assistant-container">
                <Row>
                    <Col>
                        <Card className="chat-container madevo-card">
                            <Card.Body>
                                <Row>
                                    <Col xs={12} className="d-lg-none">
                                    Summary
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        <div className="h-100">
                                            <Card className="chat-history mb-2 overflow-auto" ref={chatHistoryWindow}>
                                                <Card.Body className="p-0 mh-100">
                                                    {activeConversation.length > 0 && history.messages && history.messages.length > 0 &&
                                                        history.messages.map((chat, idx) => {
                                                            let userStyle = chat.who === "user" ? { borderRight: "2px solid #f20040" } : { borderLeft: "2px solid #4fb2eb" };
                                                            let msg = chat.what.message;
                                                            try {
                                                                if (msg.startsWith("{") && msg.endsWith("}")){
                                                                    msg = JSON.parse(msg);
                                                                }
                                                            } catch (e) {
                                                            }
                                                            return <div className="w-100 border-top border-bottom px-2 py-1 mt-1 message" style={userStyle} key={chat.when}>
                                                                {chat.who === "user" &&
                                                                    <div className="d-flex w-100 justify-content-between" title={moment.parseZone(chat.when).format("YYYY-MM-DD@HH:mm:ss")}>
                                                                        <small className="text-secondary mx-2">
                                                                            {moment.parseZone(chat.when).fromNow()}
                                                                        </small>
                                                                        <span>
                                                                            {chat.who}
                                                                            <Image className="my-1 mx-2" src="/icon.png" width={16} roundedCircle />
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {chat.who === "assistant" &&
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <span>
                                                                            <Image className="my-1 mx-2" src="/assistant_icon.png" width={16} roundedCircle />
                                                                            <small>{t("Assistant")}</small>
                                                                        </span>
                                                                        <small className="text-secondary mx-2" title={moment.parseZone(chat.when).format("YYYY-MM-DD@HH:mm:ss")}>
                                                                            {moment.parseZone(chat.when).fromNow()}
                                                                        </small>
                                                                    </div>
                                                                }
                                                                <div className="d-flex w-100 justify-content-start flex-column">
                                                                    {chat.who === "assistant" && chat.what.message && msg && msg.response && msg.response.includes("Invalid API Key") &&
                                                                        <span className="text-danger">{msg.response}</span>
                                                                    }
                                                                    {chat.who === "assistant" && msg && !msg.response &&
                                                                        <span className="text-danger">{msg.detail}</span>
                                                                    }
                                                                    {chat.who === "assistant" && chat.what.message && msg && msg.response && !msg.response.includes("Invalid API Key") &&
                                                                        <div dangerouslySetInnerHTML={{ __html: msg.response }}></div>
                                                                    }
                                                                    {chat.who === "user" && msg &&
                                                                        msg
                                                                    }
                                                                    {chat.what.confirm &&
                                                                        <ConfirmMessage id={activeConversation} idx={idx} what={chat.what} reLoad={loadHistory} />
                                                                    }
                                                                    {chat.who === "assistant" && chat.what.message && msg && msg.visualization && msg.visualization!="" && !msg.response.includes("Invalid API Key") && 
                                                                        (()=>{
                                                                            try{
                                                                                if (msg.visualization.startsWith("data:image")) {
                                                                                    return (
                                                                                        <img style={{"width":"100%","maxWidth":"700px","margin":"auto"}} src={msg.visualization} />
                                                                                    );
                                                                                } else if (msg.visualization.startsWith("plain:")) {
                                                                                    let opacity = 1
                                                                                    if (msg.visualization.includes("loading_chart")) {
                                                                                        opacity = 0.2
                                                                                    }
                                                                                    return (
                                                                                        <img style={{"width":"100%","maxWidth":"700px","margin":"auto", "opacity":opacity}} src={msg.visualization.replace("plain:","")} />
                                                                                    );
                                                                                }
                                                                                
                                                                                let visualization = JSON.parse(msg.visualization);
                                                                                // let visualization = 
                                                                                if (!visualization.dataset){ return; }
                                                                                
                                                                                if (["LineChart", "BarChart", "PieChart", "ScatterChart"].includes(visualization.chartType)){
                                                                                    const chartComponents = {
                                                                                        LineChart: LineChart,
                                                                                        BarChart: BarChart,
                                                                                        PieChart: PieChart,
                                                                                        ScatterChart: ScatterChart,
                                                                                    };
                                                                                    
                                                                                    if (visualization.dataset.length == 0){ return; }

                                                                                    visualization.dataset.forEach((set)=>{
                                                                                        set.dataKeySeries = visualization.series.datakey;
                                                                                        set.dataKeyX = visualization.xAxis.datakey;
                                                                                        set.dataKeyY = visualization.yAxis.datakey;
                                                                                    })
                                                                                    
                                                                                    const dataset = visualization.dataset.map((item)=>{
                                                                                        if (item['timestamp']){
                                                                                            item['timestamp'] = moment.parseZone(item['timestamp']);
                                                                                        }
                                                                                        return item;
                                                                                    });
                                                                                    // console.log(visualization);
                                                                                    // visualization.xAxis.valueFormatter = (v) => {
                                                                                    //     return `${v}`;
                                                                                    // }
                                                                                    // visualization.yAxis.valueFormatter = (v) => {
                                                                                    //     return `${v}`;
                                                                                    // }
                                                                                    // visualization.series.valueFormatter = (v) => {
                                                                                    //     return `${v}`;
                                                                                    // }
                                                                                    // visualization.xAxis.data = visualization.dataset.map((item)=>{
                                                                                    //     return item[item.dataKeyX];
                                                                                    // });
                                                                                    // visualization.yAxis.data = visualization.dataset.map((item)=>{
                                                                                    //     return item[item.dataKeyY];
                                                                                    // });
                                                                                    if (!Array.isArray(visualization.series)) {
                                                                                        visualization.xAxis.data = visualization.dataset.map((item)=>{
                                                                                            return item[item.dataKeyX];
                                                                                        });
                                                                                        visualization.yAxis.data = visualization.dataset.map((item)=>{
                                                                                            return item[item.dataKeyY];
                                                                                        });
                                                                                        visualization.series.data = visualization.dataset.map((item)=>{
                                                                                            if (item.hasOwnProperty(item.dataKeySeries)){
                                                                                                return item[item.dataKeySeries];
                                                                                            }
                                                                                            return item[item.dataKeyY];
                                                                                        });
                                                                                    } else {
                                                                                        for (let i = 0; i < visualization.series.length; i++) {
                                                                                            if(visualization.series[i].type){
                                                                                                visualization.series[i].type = visualization.series[i].type.toLowerCase();
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    if (!["band","linear","log","point","pow","sqrt","time","utc"].includes(visualization.xAxis.scaleType)){
                                                                                        visualization.xAxis.scaleType = "band";
                                                                                    }
                                                                                    if (visualization.chartType == "BarChart"){
                                                                                        visualization.xAxis.scaleType = "band";
                                                                                    }
                                                                                    visualization.yAxis.labelStyle = { "transformOrigin": "-15px 15px" };
                                                                                    const yAxis = [visualization.yAxis];
                                                                                    const xAxis = [visualization.xAxis];
                                                                                    visualization.series.showMark = true;
                                                                                    visualization.series.area = false;
                                                                                    
                                                                                    const series = Array.isArray(visualization.series)?visualization.series:[visualization.series];
                                                                                    const ChartComponent = chartComponents[visualization.chartType];
                                                                                    // if (visualization.chartType == "BarChart"){
                                                                                    //     console.log(visualization);
                                                                                    // }
                                                                                    return (
                                                                                        <ErrorBoundary onError={myErrorHandler} fallback="Chart could not be generated.">
                                                                                            <ChartComponent 
                                                                                            height={400}
                                                                                            margin={{left: 70}}
                                                                                            dataset={dataset}
                                                                                            xAxis={xAxis}
                                                                                            yAxis={yAxis}
                                                                                            series={series}
                                                                                            />
                                                                                        </ErrorBoundary>
                                                                                    );
                                                                                }
                                                                            }catch(err){
                                                                                // console.log(err)
                                                                            }
                                                                        })()
                                                                    }
                                                                    {chat.what.plot &&
                                                                        <div className="d-flex w-100 justify-content-center">
                                                                            <LineChart className='w-100 justify-content-center'
                                                                                xAxis={[
                                                                                    {
                                                                                        data: chat.what.plot.data.map((item, idx) => moment.parseZone(item.timestamp)),
                                                                                        scaleType: "utc",
                                                                                        tickNumber: 3,
                                                                                        valueFormatter: (v, c) => {
                                                                                            return moment.parseZone(v).format("YYYY-MM-DD@HH:mm:ss.SS");
                                                                                        }
                                                                                    },
                                                                                ]}
                                                                                series={[
                                                                                    {
                                                                                        curve: "linear",
                                                                                        showMark: false,
                                                                                        data: chat.what.plot.data.map((item) => parseFloat(item.value)),
                                                                                        valueFormatter: (v) => {
                                                                                            return `${v}`;
                                                                                        }
                                                                                    },
                                                                                ]}
                                                                                height={500}
                                                                            />
                                                                        </div>

                                                                    }
                                                                    {chat.what.table &&
                                                                        <div className="d-flex w-100 justify-content-center">
                                                                            <Table striped bordered hover size="sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {chat.what.table.labels.map((th) => {
                                                                                            return <th key={th}>{th}</th>
                                                                                        })}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        chat.what.table.data.map((row) => {
                                                                                            return <tr key={row}>
                                                                                                {row.map((col) => {
                                                                                                    return <td key={col}>{col}</td>
                                                                                                })}
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {isAILoading &&
                                                        <div className="w-100 border-top border-bottom px-2 py-1 mt-1 ai-loading" style={{ borderLeft: "2px solid #4fb2eb" }}>
                                                            <div className="d-flex w-100 justify-content-start">
                                                                <span>
                                                                    <Image className="my-1 mx-2" src="/assistant_icon.png" width={16} roundedCircle />
                                                                    <small>{t("Assistant")}</small>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex w-100 justify-content-center">
                                                                <span className="loader"></span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        activeConversation.length === 0 &&
                                                        <h3 className="mt-4 text-secondary w-100 text-center">{t("Please select a Conversation")}</h3>
                                                    }
                                                </Card.Body>
                                            </Card>
                                            <Card className="new-message p-2 mt-2">
                                                <InputGroup className="">
                                                    <Form.Control
                                                        aria-label="Chat Input"
                                                        aria-describedby="chat-input"
                                                        onChange={(e) => setCurrentMessage(e.target.value)}
                                                        onKeyUp={(e) => { if (e.key === "Enter") sendMessage(); }}
                                                        value={currentMessage}
                                                        disabled={activeConversation.length === 0}
                                                    />
                                                    <Button
                                                        variant="outline-secondary"
                                                        id="send-msg"
                                                        onClick={sendMessage}
                                                        disabled={currentMessage.length === 0 || activeConversation.length === 0}
                                                    >
                                                        <Send />
                                                    </Button>
                                                </InputGroup>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="d-none d-lg-block">
                                        <Row>
                                            <Col>Summary</Col>
                                            <Col className="text-end"><div onClick={reloadSummary} style={{cursor: "pointer"}} title="Reload Summary"><Repeat/></div></Col>
                                        </Row>
                                    
                                    { 
                                        (()=>{
                                            try{
                                                let summaryParsed = JSON.parse(summary);
                                                let summaryResponse = JSON.parse(summaryParsed.response);
                                                // let summary = {
                                                //     "_id": null,
                                                //     "latestDataPoint": { "timestamp": "2024-08-05T13:00:26.013000", "metricValue": 0.0 },
                                                //     "dataRange": { "min": "2024-08-05T13:00:26.013000", "max": "2024-08-05T13:05:03.773000" },
                                                //     "keyMetrics": { "avg": 77.51264492753624, "min": 0.0, "max": 171.033 },
                                                //     "recentTrend": [64.844, 64.718, 62.863, 62.326, 62.303, 62.721, 61.994],
                                                //     "lastNEvents": [
                                                //         { "timestamp": "2024-08-05T13:04:59.768000", "metricValue": 62.863 },
                                                //         { "timestamp": "2024-08-05T13:05:00.770000", "metricValue": 62.326 },
                                                //         { "timestamp": "2024-08-05T13:05:01.770000", "metricValue": 62.303 },
                                                //         { "timestamp": "2024-08-05T13:05:02.771000", "metricValue": 62.721 },
                                                //         { "timestamp": "2024-08-05T13:05:03.773000", "metricValue": 61.994 }
                                                //     ],
                                                //     "dataCompleteness": null
                                                // };
                                                return <div className="chat-history-summary" dangerouslySetInnerHTML={{ __html: CTree(summaryResponse) }} />
                                            }catch(error){

                                            }
                                        })()
                                    }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div className={"conversation-container" + (!isConvOpen ? " closed" : "")}>
                <Card className="mb-3 madevo-card" border="dark shadow">
                    <Card.Title className="d-flex justify-content-between m-3 mb-0">
                        <Image id="conversation-icon" src="/icons/conversations.png" className="mt-3 ms-0" />
                        <span className="mt-3 mb-0">{t("Conversations")}</span>
                        {isLoading &&
                            <Spinner animation="border" role="status" size='sm' variant='secondary' className="mt-3 me-1">
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        }
                        {!isLoading &&
                            <PlusSquare role="button" className="mt-3 me-1" onClick={newConversation} title={t("New Conversation")} />
                        }
                    </Card.Title>
                    <Card.Body className="d-flex p-0 pb-4 pe-2">
                        <div role="button" onClick={() => setIsConvOpen(!isConvOpen)} className="controls d-flex flex-column justify-content-center">
                            {isConvOpen &&
                                <ChevronDoubleRight />
                            }
                            {!isConvOpen &&
                                <ChevronDoubleLeft/>
                            }
                        </div>
                        <div className="conv-list d-flex flex-column">
                            {conversations.length > 0 &&
                                conversations.map((c) => {
                                    return (
                                        <ListGroup className="my-1 " key={c._id}>
                                            <ListGroup.Item action onClick={() => {setIsConvOpen(!isConvOpen); loadHistory(c._id); getSummary(c._id)}} tt={c._id} active={c._id === activeConversation}>
                                                <div className="d-flex w-100 justify-content-start">
                                                    <strong className="text-small">{c.title}</strong>
                                                </div>
                                                <div className="d-flex w-100 justify-content-between">
                                                    <small className="mb-1" title={moment.parseZone(c.created_at).format("YYYY-MM-DD@HH:mm:ss")}>{moment.parseZone(c.created_at).fromNow()}</small>
                                                    <Trash
                                                        role="button"
                                                        className="list-action red-action icon-small m-1 madevo-secondary"
                                                        onClick={() => handleDelete(c._id)}
                                                    />
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    )
                                })
                            }
                            {conversations.length == 0 &&
                                <span className="text-secondary">{t("No Conversations")}</span>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {showEdit &&
                <ConversationEdit show={showEdit} handleShow={setShowEdit} conversation_id={activeConversation} setConversationId={setActiveConversation} reLoad={loadAssistantConversations} />
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.conversation")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
            <Footer />
        </Container>
    );
}

export default Assistant;

