import React, { useState } from 'react';
import AuthContext from './AuthContext';
import axios from "axios";
import Cookies from "js-cookie";
import api from '../api';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState("");
    const [userRole, setUserRole] = useState({});

    const login = async (email, password) => {
        const response = await axios
        .post(
            API_URL + "user/login",
            {
                email,
                password,
            },
            { withCredentials: true }
        );
        const token = response.data.token;
        if (token) {
            localStorage.setItem("madevo-app-token", token);
        }
        setLoggedIn(true);
        setUserId(response.data.user.id);
        setUserRole(response.data.user.role);
        return response.data;
    };

    const register = async (email, password) => {
        const response = await axios
        .post(
            API_URL + "user/register",
            {
                email: email,
                password: password,
                is_dummy: false,
            },
        );
        return response.data;
    };

    const logout = async () => {
        try {
            const response = await axios
            .post(
                API_URL + "auth/logout",
                {},
                { withCredentials: true }
            );
            localStorage.removeItem("madevo-app-user");
            localStorage.removeItem("madevo-app-token");
            setLoggedIn(false);
            window.location = "/";
            return response;
        } catch (e) {
            const res = e.response.data;
            if (res.error) {
                setLoggedIn(false);
                setUserId("");
                window.location = "/";
            }
        }
    };

    const checkLoggedIn = async () => {
        try {
            const accessToken = localStorage.getItem('madevo-app-token');
            if (accessToken) {
                const { exp } = jwtDecode(accessToken);
                const isExpired = Date.now() / 1000 >= exp;
                if (isExpired) {
                    const response = await axios.post(API_URL + 'refresh-token', null, { withCredentials: true });
                    if (response && response.data && !response.data.error) {
                        const newAccessToken = response.data.token;
                        if (newAccessToken) {
                            localStorage.setItem("madevo-app-token", newAccessToken);
                        } else {
                            localStorage.removeItem('madevo-app-token');
                            return false;
                        }
                    } else {
                        localStorage.removeItem('madevo-app-token');
                        return false;
                    }
                }
            } else {
                return false;
            }

            let ret_id = userId;
            if (!isLoggedIn) {
                const response = await axios.get(API_URL + "user/is-logged-in", { withCredentials: true });
                const isAuthenticated = !response.data.error;
                if (isAuthenticated) {
                    const uid = response.data.id;
                    setUserId(uid);
                    setLoggedIn(true);
                    ret_id = uid;
                } else {
                    // console.log("is not authenticated");
                    // logout();
                    return false;
                }
            }
            
            const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
            return axios.get(ENDPOINT, {
                params: { id: ret_id },
                withCredentials: true,
            }).then((l_res) => {
                setUserRole(l_res.data.user.role);
                return ret_id;
            }).catch((e) => {
                if (!e.response) return false;
                const res = e.response.data;
                if (res.error) {
                    setUserId("");
                    setLoggedIn(false);
                    setUserRole({});
                    localStorage.removeItem('madevo-app-token');
                    return false;
                }
            });            

            // return axios
            //     .get(API_URL + "user/is-logged-in", { withCredentials: true })
            //     .then((response) => {
            //         let ret = "";
            //         const isAuthenticated = !response.data.error;
            //         if (isAuthenticated) {
            //             const uid = response.data.id;
            //             setUserId(uid);
            //             setLoggedIn(true);
            //             ret = uid;
            //         } else {
            //             axios.post(API_URL + "user/is-logged-in", {}, { withCredentials: true })
            //                 .then((res) => {
            //                     if (res.error) {
            //                         logout();
            //                     } else {
            //                         ret = userId;
            //                     }
            //                 });
            //             logout();
            //         }
            //         return ret;
            //     })
            //     .then((res) => {
            //         const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
            //         return axios.get(ENDPOINT, {
            //             params: { id: res },
            //             withCredentials: true,
            //         })
            //             .then((l_res) => {
            //                 setUserRole(l_res.data.user.role);
            //                 return res;
            //             })
            //     })
            //     .catch((e) => {
            //         if (!e.response) return false;
            //         const res = e.response.data;
            //         if (res.error) {
            //             setUserId("");
            //             setLoggedIn(false);
            //             setUserRole({});
            //             return false;
            //         }
            //     });
        } catch (error) {
            // console.error("Error checking login status: ", error);
            setUserId("");
            setLoggedIn(false);
            localStorage.removeItem('madevo-app-token');
            return false;
        }

    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userId, userRole, login, register, logout, checkLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
// export const AuthProvider = () => React.useContext(AuthContext);
